import { configureStore } from '@reduxjs/toolkit';
import authSlice from '../features/Auth/authSlice';
import beatDetailsSlice from '../features/BeatDetails/beatDetailsSlice'
import snackbarSlice from '../features/Snackbar/snackbarSlice';
import storage from "redux-persist/lib/storage";
import { persistReducer } from 'redux-persist';
import { persistStore } from "redux-persist";
import { combineReducers } from "redux";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
  blacklist: ["beatDetails"]
};

const reducers = combineReducers({
  auth: authSlice,
  beatDetails: beatDetailsSlice,
  snackbarState: snackbarSlice
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
