import axios from 'axios';
import { base_url, from_oms } from "../apis/api";
import { detect } from 'detect-browser'
// Create instance called instance

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
//  function getMobileOperatingSystem() {
//   var userAgent = navigator.userAgent || navigator.vendor || window.opera;

//   // Windows Phone must come first because its UA also contains "Android"
//   if (/windows phone/i.test(userAgent)) {
//       return "Windows Phone";
//   }

//   if (/android/i.test(userAgent)) {
//       return "Android";
//   }

//   // iOS detection from: http://stackoverflow.com/a/9039885/177710
//   if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
//       return "iOS";
//   }

//   return "Not known";
// }

export const getOs = (e) => {
  const browser = detect();
  // console.log("browser.os", browser.os)
  // var Name = "Not known";
  // if (getMobileOperatingSystem() !== "Not known")
  //   Name = getMobileOperatingSystem()
  // else if (navigator.appVersion.indexOf("Win") != -1) Name =
  //   "Windows OS";
  // else if (navigator.appVersion.indexOf("Mac") != -1) Name =
  //   "MacOS";
  // else if (navigator.appVersion.indexOf("X11") != -1) Name = 
  //   "UNIX OS";
  // else if (navigator.appVersion.indexOf("Linux") != -1) Name = 
  //   "Linux OS";
  // else {
  //   Name = getMobileOperatingSystem()
  // }

  return browser.os;
}

function createInstance(ip) {
  const instance = axios.create({
    baseURL: base_url,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "User-Agent-Device":  getOs(),
      "From-Oms": from_oms         
    },
  });

  // instance.interceptors.response.use(function (config) {
  //   console.log("axios middleware")
  //   return config
  // });

  return instance;
}

// const trackingInstance = createInstance()

export const Apireq = {
  callInstance: function () {
    let getInstance = createInstance()
    this.instance = getInstance;
    this.trackingInstance = createInstance();
  },
  instance: null,
  trackingInstance: null,
  coordinates: {
    latitude: "",
    longitude: ""
  },
  setCoordinates(lat, long) {
    this.coordinates.latitude = lat;
    this.coordinates.longitude = long;
  },
  getData: function (url, params) {
      // this.callInstance()
      return this.instance({
        method: "GET",
        url: url,
        params: {
          ...params,
        },
        headers: {
          "User-Lat": this.coordinates.latitude,
          "User-Long": this.coordinates.longitude,
          
        }
      })
  },
  postData: function (url, data, config) {
    try {

      const submitTracking = () => this.trackingInstance({
        method: "POST",
        url: '/et/submit',
        data: {
          "event_type":  `submit_event_${config?.data}`
        },
        headers: {
          "User-Lat": this.coordinates.latitude,
          "User-Long": this.coordinates.longitude,
        }
      })
      
      const confirmationSubmitTracking = () => this.trackingInstance({
        method: "POST",
        url: '/et/confirmation',
        data: {
          "event_type":  `confirmation_event_${config?.data}`
        },
        headers: {
          "User-Lat": this.coordinates.latitude,
          "User-Long": this.coordinates.longitude,
        }
      })
      
      this.instance.interceptors.request.use(function (repsonse) {

        // if(config?.confirmation) {
        //   confirmationSubmitTracking()
        // }
        // else {
        //   submitTracking()
        // }

        if(config?.submit) {
          submitTracking()
          config.submit = false
        }
        else if(config?.confirmation) {
          confirmationSubmitTracking()
          config.confirmation = false
        }
        
        return repsonse
      });

      

      return this.instance({
        method: "POST",
        url: url,
        data: data,
        headers: {
          "User-Lat": this.coordinates.latitude,
          "User-Long": this.coordinates.longitude,
        }
      })
    } catch(err) {
      localStorage.clear()
    }
  },
  patchData: function (url, data) {
    return this.instance({
      method: "PATCH",
      url: url,
      data: data,
      headers: {
        "User-Lat": this.coordinates.latitude,
        "User-Long": this.coordinates.longitude,
      }
    })
  },
  putData: function (url, data) {
    return this.instance({
      method: "PUT",
      url: url,
      data: data,
      headers: {
        "User-Lat": this.coordinates.latitude,
        "User-Long": this.coordinates.longitude,
      }
    })
  },
};
