import React from 'react'
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from '@mui/material/Alert';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    snackBar: {
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
    },
}))

const ResponseSnackbar = (props) => {
    const classes = useStyles();
    return (
      <>
        <Snackbar
          open={props.open}
          autoHideDuration={props.autoHideDuration ? props.autoHideDuration :  8000}
          onClose={props.handleClose}
          anchorOrigin={{vertical: "bottom", horizontal: "center"}}
        >
          <div>
            <MuiAlert
              variant='filled'
              onClose={props.handleClose}
              severity={props.message.type}
              sx={{ width: "100%" }}
            >
              {props.message?.data}
            </MuiAlert>
          </div>
        </Snackbar>
      </>
    );
  };

export default ResponseSnackbar

// function Alert(props) {
//     return <MuiAlert elevation={6} variant="filled" {...props} />;
// }