import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { initializeUser2 as makeAuthObject } from "../../../actions/actions";
import axios from 'axios';
import { base_url } from '../../../apis/api';
import moment from 'moment';
import { Apireq } from "../../../data/getInstance";

export const snackbarSlice = createSlice({
    name: "snackbarSlice",
    initialState: {
        message: {
            type: "",
            data: ""
        },
        visible: false
    },
    reducers: {
        showSnackBar: (state, action) => {
            state.message.type = action.payload.type;
            state.message.data = action.payload.data;
            state.visible = true;
        },
        removeSnackbar: (state, action) => {
            state.visible = false;
        }
    },
    extraReducers: {}
});

// Action creators are generated for each case reducer function
export const { showSnackBar, removeSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;
