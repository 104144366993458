import { useEffect } from "react";

const removeInputNumberScroll = () => {
    document.addEventListener("wheel", function (event) {
        if (document.activeElement.type === "number") {
            document.activeElement.blur();
        }
    });
}

const useVanilla = () => {
    useEffect(() => {
        removeInputNumberScroll()
    }, []);
};

export default useVanilla;