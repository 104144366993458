import { showSnackBar, removeSnackbar } from "../Redux/features/Snackbar/snackbarSlice"
import { useDispatch } from "react-redux"

export const useSnackbar = (microseconds) => {

    const dispatch = useDispatch();

    const displaySnackbar = ({type, message}) => {
        dispatch(showSnackBar({type: type, data: message}))

        setTimeout(() => {
            dispatch(removeSnackbar())
        }, 5000);
    }

    const snackbarRemove = () => dispatch(removeSnackbar())

    const displaySnackbarNoLimit = ({type, message}) => dispatch(showSnackBar({type: type, data: message}))



    const displaySnackbarWithLimit = ({type, message}) => {
        dispatch(showSnackBar({type: type, data: message}))

        setTimeout(() => {
            dispatch(removeSnackbar())
        }, microseconds);
    }


    return { displaySnackbar, displaySnackbarNoLimit, snackbarRemove, displaySnackbarWithLimit }
}

export default useSnackbar;