import image from "./pageNotFound.png";
import { useEffect } from "react";

const PageNotFound = () => {

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      localStorage.clear()
      window.location.replace('/')
      window.location.reload()
    }
  }, [])

  return (
    <div>
      <img src={image} style={{ width: "90%", height: "90%" }} />
    </div>
  );
};

export default PageNotFound;
