import "./App.css";
import './components/Dashboard/Pages/Farmers/NewOrderForm.css';
import './Pages/Sales/NewRetailerForm.css';
import React, { useEffect, Suspense, useState } from "react";
// import { Route, Routes, Navigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { QueryClient, QueryClientProvider } from 'react-query'
import PageNotFound from "./Pages/PageNotFound/PageNotFound";
import { useSelector, useDispatch } from 'react-redux'
import isEmpty from 'lodash/isEmpty';
import { Apireq } from "./data/getInstance";
import ResponseSnackbar from "./components/Common/ResponseSnackbar";
import { clearAuth } from "./Redux/features/Auth/authSlice";
import NavigateTo from "./components/utils/navigateTo";
import useVanilla from "./utils/useVanilla";
import { removeSnackbar } from "./Redux/features/Snackbar/snackbarSlice";
import useSnackbar from "./utils/useSnackbar";
import useTracking from "./utils/useTracking";
import { useGeoLocation } from "./components/hooks/useGeolocation";
import AppRoutes from './Routes'

const lazyRetry = function(componentImport) {
  return new Promise((resolve, reject) => {
      // check if the window has already been refreshed
      const hasRefreshed = JSON.parse(
        window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
    );
    // try to import the component
    componentImport().then((component) => {
        window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
        resolve(component);
    }).catch((error) => {
        if (!hasRefreshed) { // not been refreshed yet
            window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
            return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
    });
  });
};

const queryClient = new QueryClient();
const Home = React.lazy(() => lazyRetry(()  => import('./Pages/Home')));
const SignUp = React.lazy(() => lazyRetry(()  => import('./components/SignUpPage/SignUp')));
// const SignUp = React.lazy(() => lazyRetry(()  => import('./Pages/Signup/SignupPage')))
const Login = React.lazy(() => lazyRetry(()  => import('./components/LoginPage/Login')));
const Newlogin = React.lazy(() => lazyRetry(()  => import('./components/LoginPage/Newlogin')));
const AdminSignUp = React.lazy(() => lazyRetry(()  => import('./components/SignUpPage/AdminSignUp')));



function App() {
  const login_url = process.env.REACT_APP_LOGIN_URL;
  const authState = useSelector(state => state.auth);
  const snackbarState = useSelector(state => state.snackbarState);
  const dispatch = useDispatch()
  // const navigate = NavigateTo()
  const { displaySnackbar } = useSnackbar()
  useVanilla()
  useTracking()
  const [location, accuracy, error] = useGeoLocation(true);

  const trackLocationOnReload = async () => {
    const reloadPage = window.location.pathname.replace('/dashboard/', '').replace('/dashboard', '').replace(/[0-9]/g, '').replace('/', '_').replace('//', '')
    const pageName = reloadPage !== '' ? reloadPage : 'dashboard'
    Apireq.postData('/et/reload', {
      "event_type": `reload_event_${pageName}`
    })
  }

  if (Object.keys(location).length !== 0)
    Apireq.setCoordinates(location?.lat, location?.lng)


  const [offlineStatus, setOfflineStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const today = new Date();
  useEffect(() => {
    if (authState.exp) {
      const timeStamp = authState.exp;      
      const expDate = new Date(timeStamp);
      if (expDate <= today) {
        // localStorage.clear();
        // window.location.replace(login_url);
        // dispatch(clearAuth())
        asyncLogout()
      }
    }    
    handleOffline();
    handleOnline();
  }, []);

  const asyncLogout = async () => {
    await Apireq.postData('/logout/')
      .then((resp) => {
        handleLogout()
      })
      .catch(err => {
        localStorage.clear()
        displaySnackbar({
          type: "error",
          message: "Failed to Log out..."
        })
      })
  }

  useEffect(() => {
    if (isEmpty(authState) && !!localStorage.getItem("token")) {
      asyncLogout()
    }
    else if (authState?.hasAccess?.length === 0) {
      asyncLogout()
    }
  }, [])

  // useEffect(() => {
  //   if (localStorage.getItem("token")) {
  //     Apireq.callInstance()
  //   }
  //   else {
  //     const path = window.location.pathname;
  //     if (path !== "/signup" && path !== "/adminsignup" && path !== "/oldlogin")
  //       navigate('/')
  //   }
  // }, [])

  useEffect(() => {
    if (Object.keys(location).length !== 0) {
      trackLocationOnReload(location)
    }
  }, [])

  useEffect(() => {    
    const timeStamp = authState?.exp;      
    const expDate = new Date(timeStamp);    
    if (expDate <= today) {      
      asyncLogout()
    }    
  }, [window.location.href])

  useEffect(() => {
    if(window.location.origin === "http://oms.eggoz.in") {
      window.location.replace("https://oms.eggoz.in");
    }
    if(window.location.origin === "http://sandbox-portal.eggoz.in") {
      window.location.replace("https://sandbox-portal.eggoz.in")
    }
  }, [])

  const handleLogout = () => {
    localStorage.clear();
    window.location.replace(login_url);
    dispatch(clearAuth())
  };

  const handleOffline = () => {    
    window.onoffline = (event) => {      
      setOfflineStatus(true)
      setOpen(true)

      setMessage({
        data: "Internet Connection Lost...",
        type: "error",
      });
    };
  }
  const handleOnline = () => {
    window.ononline = (event) => {
      setOfflineStatus(false)
      setOpen(false)
    };
  }
  
  return (
    <QueryClientProvider client={queryClient}>
      {/* <Suspense fallback={<div>Loading...</div>}> */}
        {
          offlineStatus ? (
            <ResponseSnackbar
              open={open}
              handleClose={handleClose}
              message={message}
            />
          ) : ""
        }
        {/* <Routes>

          {localStorage.getItem("token") ? (
            <Route path="/"  element={<Navigate to="/dashboard" />} />
          ) : (
            <Route path="/"  element={<Newlogin />} />
          )}


          <Route path="/oldlogin" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/adminsignup" element={<AdminSignUp />} />
          <Route path="/dashboard/*" exact element={<Home />}/>
          {localStorage.getItem("token") ? (
            <Route
              path="/dashboard/*"
              exact
              element={<Navigate to="/dashboard" />}
            />
          ) : (
            <Route path="/" exact element={<Navigate to="/" />} />
          )}
          <Route path="*" element={<PageNotFound />} />
        </Routes> */}
        <AppRoutes />
      {/* </Suspense> */}
      <ToastContainer />
      <ResponseSnackbar
        open={snackbarState.visible}
        handleClose={() => dispatch(removeSnackbar())}
        message={snackbarState.message}
      />
    </QueryClientProvider>
  );
}

export default App;
