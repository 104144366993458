import { useEffect } from 'react';
import { Apireq } from '../data/getInstance';

const asyncEventTrackingRequest = async (key) => {
    try {
        await Apireq.postData('/et/click', {
            "event_type": `click_event_${key}`
        })
    }catch(err) {
        console.log("error event tracking")
    }
}

const eventTracking = (data) => {
    asyncEventTrackingRequest(data)
}

const handleClick = (e) => {
    // console.log("e: ", e.currentTarget.dataset?.["event_tracking"])
    const event = e.currentTarget.dataset?.["event_tracking"]
    
    eventTracking(event)
}

/**
 * if there is any dom change this function will run 
 * and remove and attach new event listeners
 * to track data-event_tracking dom elements
 */

const observeEventTrackingNodes = () => {
    //mutation observer to observe any dom change
    const rootTag = document.querySelector("#root")
    const mutationObserver = new MutationObserver(entries =>{
        removeListeners()
        attachListeners()
    })
    mutationObserver.observe(rootTag, {
        subtree: true,
        childList: true
    })
}


const removeListeners = () => {
    const els = Array.from(document.querySelectorAll('[data-event_tracking]'));
    els.forEach(el => {
        el.removeEventListener('click', handleClick)
    })
}

const attachListeners = () => {
    const els = document.querySelectorAll('[data-event_tracking]');

    els.forEach(el => {
            el.addEventListener('click', handleClick);
    })
}



export const useTracking = () => {

    useEffect(() => {
        observeEventTrackingNodes()
    }, [])

}

export default useTracking;