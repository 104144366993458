import { createContext, useEffect, useState, useReducer, useContext } from "react";
import { authReducer, initialisedState } from './AuthContextReducer';


// sales: {
//     manager,
//         regional manager,
//             regional adhoc manager,
//                 worker,
//                 zonal manager
// }

// warehouse: {
//     manager
// }

// distribution: { //aka warehouse
//     manager,
//         worker //<---field sales executive
// }

// ecomm: {
//     regional manager,
//         worker
// }

// farmAdmin: {
//     regional Admin,
//     // customer,
// }

// finance: {
//     worker
// }














// const AuthObject = {
//     userId: "",
//     token: "",
//     profile: "distribution || sales || ecomm ...", hasKey(salesPersonProfile)
//     personStatus: "worker || manager || regional_manager || zonal manager", //userProfile -> departmentProfile[0].managementStatus
//     hasAccess: [
//         {
//             profile: "sales",
//             personStatus: "regional manager",
//             is_ecommerce: false
//             is_mt: false

//         },
//         {
//             profile: "farmer",
//             personStatus: null
//         }
//     ], // //userProfile -> departmentProfile
//     currentAccess: {
//         profile: "sales",
//         personStatus: "manager"
//     }
// }

// const dummyObj = {

// }

export const AuthContext = createContext();
export const useAuthContext = () => useContext(AuthContext);

const AuthContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, initialisedState);

    return (
        <AuthContext.Provider value={{ state, dispatch }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContextProvider;