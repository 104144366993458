import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { initializeUser2 as makeAuthObject } from "../../../actions/actions";
import axios from 'axios';
import { base_url } from '../../../apis/api';
import moment from 'moment';
import { Apireq } from "../../../data/getInstance";


export const beatAssignment = createAsyncThunk(
    "beatDetails/beatAssignment",
    async () => {
        try {
            const newdate = moment(new Date()).format("YYYY-MM-DD");

            return await Apireq.getData(
                `/distribution/beat-assignment/current_beat_status/?beat_date=${newdate}`
            )
                .then(resp => {
                    const results = resp.data;
                    if (results.length !== 0) {
                        if (results.length > 1) {
                            return { assignedBeats: results, modalOpen: true, showButton: true }
                        }
                        else {
                            if (results[0].beat_status === "Completed") {
                                return { selectedBeat: "" }
                            }
                            else {
                                return { selectedBeat: results[0] }
                            }
                        }
                    }
                })
        } catch (error) {
            throw new Error(error.response.data.message)
        }
    }
);

export const beatDetailsSlice = createSlice({
    name: "beatDetails",
    initialState: {
        status: "idle",
        modalOpen: false,
        assignedBeats: [],
        selectedBeat: "",
        showButton: false,
        error: ""
    },
    reducers: {
        toggleModalDisplay: (state, action) => {
            state.modalOpen = !state.modalOpen
        },
        handleBeatSelect: (state, action) => {
            if (action.payload.value) {
                const beatSelectedInfo = current(state).assignedBeats.filter(item => item.id.toString() === action.payload.value)[0]
                if (beatSelectedInfo.beat_status === "Completed") {
                    state.selectedBeat = ""
                    state.modalOpen = !state.modalOpen
                }
                state.selectedBeat = current(state).assignedBeats.filter(item => item.id.toString() === action.payload.value)[0]
                state.modalOpen = !state.modalOpen
            }
        }
    },
    extraReducers: {
        [beatAssignment.pending]: (state) => {
            state.status = "loading"
        },
        [beatAssignment.fulfilled]: (state, action) => {

            if (action.payload) {
        
                state.assignedBeats = action.payload.assignedBeats ? action.payload.assignedBeats : state.assignedBeats;
                state.modalOpen = action.payload.modalOpen ? action.payload.modalOpen : false;
                state.showButton = action.payload.showButton ? action.payload.showButton : false;
                state.selectedBeat = action.payload.selectedBeat ? action.payload.selectedBeat : state.selectedBeat;
            }
            state.status = "fulfilled"
        },
        [beatAssignment.rejected]: (state, action) => {
            state.status = "error";
            state.error = action.error.message;
        }
    }
});

// Action creators are generated for each case reducer function
export const { initializeUser, toggleModalDisplay, handleBeatSelect } = beatDetailsSlice.actions;

export default beatDetailsSlice.reducer;
