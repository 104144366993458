import React, { useState, useRef } from "react";

export const useGeoLocation = (locationEnabled = false) => {
    const [enabled, setEnabled] = React.useState(locationEnabled);
    const [accuracy, setAccuracy] = React.useState(0.00);
    const [location, setLocation] = useState({ lat: "", lng: "" });
    const [error, setError] = React.useState("");
    const navigatorRef = useRef(navigator.geolocation)

    React.useEffect(() => {
        if (navigatorRef.current) {
            navigatorRef.current.getCurrentPosition((e) => {
                const latitude = e.coords.latitude;
                const longitude = e.coords.longitude;
                setAccuracy(e.coords.accuracy);

                // if (position.coords.accuracy <= 50.00) {
                setLocation({ lat: latitude, lng: longitude });
            }, (e) => console.log("error: ", e),
                {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: Infinity,
                }
            );
            navigator.permissions
                .query({ name: "geolocation" })
                .then(function (result) {
                    if (result.state === "granted" || result.state === "prompt") {
                        setEnabled(true)
                    } else if (result.state === "denied") {
                        // window.alert("Please give location access to conitue....")
                        navigator.geolocation.getCurrentPosition((e) => console.log("success: ", e), (e) => console.log("error: ", e));

                    }
                    result.onchange = function () {
                        if ((navigator.geolocation) && (result.state === "granted")) {
                            let timeout;
                            const geoId = navigator.geolocation.watchPosition(
                                (position) => {
                                    const lat = position.coords.latitude;
                                    const lng = position.coords.longitude;
                                    setAccuracy(position.coords.accuracy);
                                    setLocation({ lat: lat, lng: lng });
                                },
                                (e) => {
                                    setError(e.message);
                                },
                                { enableHighAccuracy: true, maximumAge: 0, timeout: 400 }
                            );
                            timeout = setTimeout(() => {
                                if (!accuracy || accuracy < 10.00) {
                                    setError('Failed to reach desired accuracy');
                                }
                            }, 10000);
                            return () => {
                                window.navigator.geolocation.clearWatch(geoId);
                                if (timeout) {
                                    clearTimeout(timeout);
                                }
                            };
                        }
                    };
                });
        }
        if (navigator.geolocation) {
            let timeout;
            const geoId = navigator.geolocation.watchPosition(
                (position) => {
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;
                    setAccuracy(position.coords.accuracy);

                    // if (position.coords.accuracy <= 50.00) {
                    setLocation({lat: latitude, lng: longitude});
                    // }
                },
                (e) => {
                    setError(e.message);
                },
                { enableHighAccuracy: true, maximumAge: 0, timeout: 400 }
            );
            timeout = setTimeout(() => {
                if (!accuracy || accuracy < 10.00) {
                    setError('Failed to reach desired accuracy');
                }
            }, 10000);
            return () => {
                window.navigator.geolocation.clearWatch(geoId);
                if (timeout) {
                    clearTimeout(timeout);
                }
            };
        }

        setError('Geolocation API not available');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enabled, accuracy]);

    if (!enabled) {
        return [undefined, undefined, undefined];
    }

    return [location, accuracy, error];
};
