import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { StateProvider } from "./components/ContextApi/StateProvider";
import AuthProvider from "./contexts/AuthContext/AuthContext";
import reducer, { initialState } from "./components/ContextApi/reducer";
import { store, persistor } from './Redux/app/store'
import { Provider } from 'react-redux'
import { PersistGate } from "redux-persist/integration/react";
import { createTheme, ThemeProvider, adaptV4Theme } from "@mui/material/styles";
import { StyledEngineProvider } from '@mui/material/styles';


const theme = createTheme((adaptV4Theme({
  palette: {
    primary: {
      main: '#0971f1',
      darker: '#053e85',
    },
    grey: {
      main: '#e0e0e0',
      contrastText: '#fff',
    },
    blue: {
      main: '#0FA9FF',
      contrastText: '#fff',
    }
  },
  components: {
    // Name of the component
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: "0.875rem"
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          padding: '0.5rem 1rem',
          color: "black",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          padding: '0.5rem 1rem',
          color: "black"
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "#f50057",
        }, 
      }
    },
  },
})));


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    {/* <BrowserRouter> */}
	<div>
	<Provider store={store}>
        <PersistGate persistor={persistor}>
          <AuthProvider>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                  <StyledEngineProvider injectFirst>
                      <StateProvider initialState={initialState} reducer={reducer}>
                        <App />
                      </StateProvider>
                  </StyledEngineProvider>
                </ThemeProvider>
              </StyledEngineProvider>
          </AuthProvider>
        </PersistGate>
      </Provider>
	</div>
    {/* </BrowserRouter> */}
  </React.StrictMode>
);

// ReactDOM.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <Provider store={store}>
//         <PersistGate persistor={persistor}>
//           <AuthProvider>
//             <ThemeProvider theme={theme}>
//               <StyledEngineProvider injectFirst>
//                 <CacheProvider value={cache}>
//                   <StateProvider initialState={initialState} reducer={reducer}>
//                     <App />
//                   </StateProvider>
//                 </CacheProvider>
//               </StyledEngineProvider>
//             </ThemeProvider>
//           </AuthProvider>
//         </PersistGate>
//       </Provider>
//     </BrowserRouter>
//   </React.StrictMode>,
//   document.getElementById("root")
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
