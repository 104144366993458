import { createSlice } from "@reduxjs/toolkit";
import { initializeUser2 as makeAuthObject } from "../../../actions/actions";

export const authSlice = createSlice({
  name: "auth",
  initialState: {},
  reducers: {
    initializeUser: (state, action) => {
        const authObj = makeAuthObject(action.payload)
        return {
            ...authObj 
        }
    },
    updateCurrentAccess: (state, action) => {
        state.currentAccess = {...action.payload}
        return state;
    },
    clearAuth: (state, action) => {
      return {}
    }
  }
});

// Action creators are generated for each case reducer function
export const { initializeUser,updateCurrentAccess, clearAuth } = authSlice.actions;

export default authSlice.reducer;