import { ActionTypes } from "../../actions/actionTypes";

export const initialisedState = {
    // userId: "",
    // token: "",
    // profile: "",
    // personStatus: "", //userProfile -> departmentProfile[0].managementStatus
    // hasAccess: [], // //userProfile -> departmentProfile
    // currentAccess: {}
}


export const authReducer = (state = initialisedState, action) => {
    switch (action.type) {

        case ActionTypes.INITIALIZE_USER:

            return {
                ...action.payload
            };

        case ActionTypes.UPDATE_CURRENT_ACCESS: {
            return {
                ...state, currentAccess: action.payload,
            }
        }



        default:
            return state;
    }
};

export default authReducer;
