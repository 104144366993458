import { ActionTypes } from "./actionTypes";
const base_url = process.env.REACT_APP_BASE_URL;

export const initializeUser2 = (loginResObject) => {
  if (loginResObject.token) {

    function computeProfileAccessData(accessList, departmentsList) {
      if (!accessList) {
        return [];
      }
      const finalData = accessList.reduce((acc, curItem, index) => {
        let currObj = { ...curItem };
        // currObj.management_status = curItem.management_status;
        // currObj.management_profile = findPersonProfile(index, departmentsList)
        return [...acc, currObj]
      }, []);

      return finalData;

    }

    // function findPersonProfile(indx, departmentsArr) {
    //   const departmentsList = [...departmentsArr]
    //   return departmentsList[indx]
    // }

    // function removeCustomerProfile(accessList, accessObjectList) {
    //   const customerObjIndx = accessList.indexOf("Customer")
    //   const removedCustomerList = accessList.filter(i => i !== "Customer")
    //   const removeCustomerObjFromList = accessObjectList.filter((val, indx) => {
    //     return indx !== customerObjIndx
    //   })

    //   return { removedCustomerList, removeCustomerObjFromList }
    // }

    function removeProfile(accessList, accessObjectList, Profile) {
      const removedProfileList = accessList.filter(i => i !== Profile)
      const removeProfileObjFromList = accessObjectList.filter((val, indx) => {
        return val.management_profile !== Profile
      })

      return { removedProfileList, removeProfileObjFromList }
    }

    const {removedProfileList: removedCustomerList, removeProfileObjFromList: removeCustomerObjFromList} = removeProfile(
      [...loginResObject?.user?.userProfile?.departments],
      [...loginResObject?.user?.userProfile?.department_profiles],
      "Customer"
    )

    const {removedProfileList: removedFarmerList, removeProfileObjFromList: removeFarmerObjFromList} = removeProfile(
      removedCustomerList,
      removeCustomerObjFromList,
      "Farmer"
    )

    const hasAccessArr = computeProfileAccessData([...removeFarmerObjFromList], removedFarmerList)

    let loginData = {
      token: loginResObject.token,
      base_url: base_url,
      user_id: loginResObject.user.id,
      id: loginResObject.user.default_address?.city?.id,
      city_name: loginResObject.user.userCities,
      exp: loginResObject.user.userTokenData.token_exp,
      user_name: loginResObject.user.name,
      hasAccessList: [...removedFarmerList],
      hasAccess: hasAccessArr,
      currentAccess: hasAccessArr[0] ?? {management_profile: "", management_status: ""},
      ...loginResObject
    }
    if (loginResObject.user.is_manager) {
      loginData.staff_status = "Manager"
    }


    // if (loginResObject.user.userProfile?.department_profiles?.length > 0) {
    //   loginResObject.user.userProfile?.department_profiles?.map((item) =>
    //     setProfiles(item)
    //   );
    // }
    // if (
    //   typeof loginResObject.user.userProfile.warehouse_ids !== "undefined" &&
    //   loginResObject.user.userProfile?.warehouse_ids?.length > 0
    // ) {
    //   loginResObject.user.userProfile?.warehouse_ids?.map((item) =>
    //     setWarehouseIds(item)
    //   );
    // }

    // function setProfiles(item) {
    //   if (item.opsPersonProfile) {
    //     loginData.opsPersonProfile = item.opsPersonProfile;
    //     loginData.opsPersonStatus = item.management_status;
    //   } else if (item.salesPersonProfile) {
    //     loginData.salesPersonProfile = item.salesPersonProfile;
    //     loginData.is_mt_profile = item.is_mt;
    //     loginData.is_ecommerce = item.is_ecommerce;
    //     loginData.salesPersonStatus = item.management_status;
    //   } else if (item.supplyPersonProfile) {
    //     loginData.supplyPersonProfile = item.supplyPersonProfile;
    //     loginData.supplyPersonStatus = item.management_status;
    //   } else if (item.warehousePersonProfile) {
    //     loginData.warehousePersonProfile = item.warehousePersonProfile;
    //     loginData.warehousePersonStatus = item.management_status;
    //   } else if (item.distributionPersonProfile) {
    //     loginData.distributionPersonProfile = item.distributionPersonProfile;
    //     loginData.is_ecommerce = item.is_ecommerce;
    //     loginData.distributionPersonStatus = item.management_status;
    //   } else if (item.ecommerceSalesPersonProfile) {
    //     loginData.ecommerceSalesPersonProfile = item.ecommerceSalesPersonProfile;
    //     loginData.ecommerceSalesPersonStatus = item.management_status;
    //   } else if (item.farmAdminProfile) {
    //     loginData.farmAdminProfile = item.farmAdminProfile;
    //   } else if (item.financeProfile) {
    //     loginData.financePersonProfile = item.financeProfile;
    //     loginData.financePersonStatus = item.management_status;
    //   }
    // }

    // function setWarehouseIds(item) {
    //   if (item.warehouseId) {
    //     loginData.warehouseId = item.warehouseId;
    //   } else if (item.salesWarehouseId) {
    //     loginData.salesWarehouseId = item.salesWarehouseId;
    //   } else if (item.supplyWarehouseId) {
    //     loginData.supplyWarehouseId = item.supplyWarehouseId;
    //   } else if (item.opsWarehouseId) {
    //     loginData.opsWarehouseId = item.opsWarehouseId;
    //   } else if (item.distributionWarehouseId) {
    //     loginData.distributionWarehouseId = item.distributionWarehouseId;
    //   }
    // }
    return loginData
  }
  return {

  }
}

export const changeCurrentAccess = (obj) => {
  return {
    type: ActionTypes.UPDATE_CURRENT_ACCESS,
    payload: obj
  }
}
