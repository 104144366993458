import { Route, Routes, Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
import React, { Suspense, useState, useEffect } from 'react';
import PageNotFound from "./Pages/PageNotFound/PageNotFound";

import "./App.css";
import './components/Dashboard/Pages/Farmers/NewOrderForm.css';
import './Pages/Sales/NewRetailerForm.css';
import 'react-toastify/dist/ReactToastify.css';
import { Apireq } from "./data/getInstance";
import NavigateTo from "./components/utils/navigateTo";

const lazyRetry = function(componentImport) {
    return new Promise((resolve, reject) => {
        // check if the window has already been refreshed
        const hasRefreshed = JSON.parse(
          window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
      );
      // try to import the component
      componentImport().then((component) => {
          window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
          resolve(component);
      }).catch((error) => {
          if (!hasRefreshed) { // not been refreshed yet
              window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
              return window.location.reload(); // refresh the page
          }
          reject(error); // Default error behaviour as already tried refresh
      });
    });
  };
const Newlogin = React.lazy(() => lazyRetry(() => import('./components/LoginPage/Newlogin')));
const Login = React.lazy(() => lazyRetry(() => import('./components/LoginPage/Login')));
const SignUp = React.lazy(() => lazyRetry(() => import('./components/SignUpPage/SignUp')));
const AdminSignUp = React.lazy(() => lazyRetry(() => import('./components/SignUpPage/AdminSignUp')));
const Home = React.lazy(() => lazyRetry(() => import('./Pages/Home')));

function Root() {

    const navigate = NavigateTo()

    useEffect(() => {
        if (localStorage.getItem("token")) {
            Apireq.callInstance()
        }
        else {
            const path = window.location.pathname;
            if (path !== "/signup" && path !== "/adminsignup" && path !== "/oldlogin")
                navigate('/')
        }
    }, [])

    return (
        <Suspense fallback={<div>Loading...</div>}>

            <Routes>
                <Route path="/signup" element={<SignUp />} />

                {localStorage.getItem("token") ? (
                    <Route path="/" element={<Navigate to="/dashboard" />} />
                ) : (
                    <Route path="/" element={<Newlogin />} />
                )}


                <Route path="/oldlogin" element={<Login />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/adminsignup" element={<AdminSignUp />} />
                <Route path="/dashboard/*" exact element={<Home />} />
                {localStorage.getItem("token") ? (
                    <Route
                        path="/dashboard/*"
                        exact
                        element={<Navigate to="/dashboard" />}
                    />
                ) : (
                    <Route path="/" exact element={<Navigate to="/" />} />
                )}
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </Suspense>
    )
}

const router = createBrowserRouter([
    { path: "*", Component: Root }
]);

export default function ReactRoutes() {
    return <RouterProvider router={router} />;
}